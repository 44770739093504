var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "col-md-12" }, [
    _c(
      "div",
      { staticClass: "main-nav row no-gutters" },
      [
        _c("router-link", {
          staticClass: "main-nav__logo main-nav__item block-md--xl-left",
          attrs: { to: _vm.homeLink },
        }),
        _c("div", { staticClass: "col" }, [
          _c(
            "ul",
            { staticClass: "main-nav__nav_items row align-items-center" },
            _vm._l(_vm.links, function (link) {
              return _c(
                "li",
                { staticClass: "main-nav__item col-auto" },
                [
                  _c(
                    "router-link",
                    {
                      class: link.warn
                        ? "main-nav__item-link--warn main-nav__item-link"
                        : "main-nav__item-link",
                      attrs: {
                        to: link.to,
                        activeClass: "main-nav__item-link--active",
                      },
                    },
                    [link.display ? [_vm._v(_vm._s(link.display))] : _vm._e()],
                    2
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "col-2 height-xs--inherit" }, [
          _c(
            "ul",
            {
              staticClass:
                "row no-gutters justify-content-end height-xs--inherit",
            },
            _vm._l(_vm.menus, function (menu) {
              return _c("nav-menu", {
                key: Math.random(),
                staticClass: "col-auto",
                attrs: { menu: menu },
              })
            }),
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }