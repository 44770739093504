<template>
    <li>
        <div
            :class="['main-nav__menu', 'height-xs--inherit', 'd-flex', 'align-items-center', {'main-nav__menu--active': dropdownEnabled}]"
            @click.stop="dropdownEnabled = !dropdownEnabled">
            <span class="main-nav__dropdown-header block-xs--xs-right">{{ menu.header() }}</span>
            <span
                :class="['dropdown-indicator', {'dropdown-indicator--active': dropdownEnabled}]"/>
        </div>

        <dropdown-list
            v-if="dropdownEnabled"
            :items="menu.items"
            class="main-nav__dropdown"
            @close="dropdownEnabled = false" />
    </li>
</template>

<script>
import dropdownList from './dropdown_list.vue'

export default {
    name: 'NavMenu',

    components: {
        dropdownList,
    },
    props: {
        menu: Object,
    },

    data() {
        return {
            dropdownEnabled: false,
        }
    },
}
</script>

<style lang="scss">
@import '~scss/mixins';

.main-nav {
    font-size: 14px;
    &__menu {
        cursor: pointer;
        padding: 0 spacing(xxs);

        &--active, &:hover {
            background-color: $grey_light_1;
        }
    }

    &__dropdown {
        right: spacing(md);
        top: 100%;
        background-color: $grey_light_2;
    }

    &__dropdown-header {
        @include roboto-bold;
    }
}
</style>
