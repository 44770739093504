<template>
    <ul class="shadow--2 dropdown">
        <template v-if="items.length">
            <slot>
                <template v-for="item in items">
                    <template v-if="item.run">
                        <div
                            :key="item.display"
                            class="dropdown__item"
                            @click="$emit('close'); item.run()"
                        >
                            {{ item.display }}
                        </div>
                    </template>

                    <template v-else>
                        <router-link
                            :key="item.display"
                            :to="item.to"
                            class="dropdown__item"
                            @click.native="$emit('close')"
                        >{{ item.display }}</router-link>
                    </template>
                </template>
            </slot>
        </template>

        <div v-else>
            Nothing here.
        </div>
    </ul>
</template>

<script>

export default {    
    name: 'DropdownList',
    props: {
        items: {
            type: Array,
            default: () => [],
        }
    },
    mounted() {
        document.addEventListener('click', () => {
            this.$emit('close')
        }, false)

    }
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.dropdown {
    z-index: zi(modal);
    position: absolute;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;

    &__item {
        padding: spacing(xs);
        cursor: pointer;

        &:hover {
            background-color: darken($grey_light_2, 3%);
        }
    }
}
</style>
