var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    [
      _c(
        "div",
        {
          class: [
            "main-nav__menu",
            "height-xs--inherit",
            "d-flex",
            "align-items-center",
            { "main-nav__menu--active": _vm.dropdownEnabled },
          ],
          on: {
            click: function ($event) {
              $event.stopPropagation()
              _vm.dropdownEnabled = !_vm.dropdownEnabled
            },
          },
        },
        [
          _c(
            "span",
            { staticClass: "main-nav__dropdown-header block-xs--xs-right" },
            [_vm._v(_vm._s(_vm.menu.header()))]
          ),
          _c("span", {
            class: [
              "dropdown-indicator",
              { "dropdown-indicator--active": _vm.dropdownEnabled },
            ],
          }),
        ]
      ),
      _vm.dropdownEnabled
        ? _c("dropdown-list", {
            staticClass: "main-nav__dropdown",
            attrs: { items: _vm.menu.items },
            on: {
              close: function ($event) {
                _vm.dropdownEnabled = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }