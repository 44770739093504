var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-list",
        { attrs: { subheader: "", flat: "" } },
        [
          _c("v-subheader", [_vm._v("Airport Delivery Status")]),
          _c("v-divider", { staticStyle: { margin: "0px" } }),
          _vm._l(_vm.items, function (item, index) {
            return [
              _c(
                "v-list-item",
                { key: item.airportIdent },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        domProps: { innerHTML: _vm._s(item.airportName) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-switch", {
                        attrs: { color: "#93BD20" },
                        on: {
                          change: function ($event) {
                            return _vm.$emit("change", item)
                          },
                        },
                        model: {
                          value: item.deliveryEnabled,
                          callback: function ($$v) {
                            _vm.$set(item, "deliveryEnabled", $$v)
                          },
                          expression: "item.deliveryEnabled",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              index != _vm.items.length - 1
                ? _c("v-divider", {
                    key: Math.random(),
                    staticStyle: { margin: "0px" },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }