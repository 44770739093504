<template>
    <nav class="col-md-12">
        <div class="main-nav row no-gutters">
            <router-link 
                :to="homeLink" 
                class="main-nav__logo main-nav__item block-md--xl-left"/>

            <div class="col">
                <ul class="main-nav__nav_items row align-items-center">
                    <li 
                        v-for="link in links" 
                        class="main-nav__item col-auto">
                        <router-link
                            :to="link.to"
                            :class="link.warn ? 'main-nav__item-link--warn main-nav__item-link' : 'main-nav__item-link'"
                            activeClass="main-nav__item-link--active"
                        >
                            <template v-if="link.display">{{ link.display }}</template>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="col-2 height-xs--inherit">
                <ul class="row no-gutters justify-content-end height-xs--inherit">
                    <nav-menu 
                        v-for="menu in menus" 
                        :key="Math.random()" 
                        :menu="menu" 
                        class="col-auto" />
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import navMenu from './nav_menu.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'NavBar',
    components: {
        navMenu,
    },
    props: {
        links: {
            type: Array,
            required: true,
        },
        menus: Array,
    },
    computed: {
        homeLink() {
            if (!this.user.deliveryPartner) {
                return {name: 'store'}
            }
            return {}
        },
        ...mapGetters(['user'])
    }
}
</script>

<style lang="scss">
@import '../scss/variables';

.main-nav {
    height: 56px;
    align-items: center;
    border-bottom: 1px $brand_color solid;
    background-color: #fff;
    font-size: 14px;
    &__logo {
        background-image: url('../img/g_hub_white.svg');
        width: 85px;
        height: 41px;
        background-position: left center;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;

        // &:hover {
        //     background-image: url('../img/ghub_logo_active.svg');
        // }
    }

    &__nav_items {
        margin-left: 33px;
    }

    .main-nav__item {

        &-link {
            margin: 0 5px;
            padding: 10px 20px;
            border-radius: 70px;

            &--active, &:hover, &:focus {
                background-color: $brand_color;
                color: #fff;
            }

            &--warn {
                &:hover, &focus {
                    background-color: $error_red;
                }
                background-color: $warn_yellow;
                color: #fff;
            }
        }
    }
}
</style>
