<template>
  <v-card>
    <v-list subheader flat>
      <v-subheader>Airport Delivery Status</v-subheader>
      <v-divider style="margin: 0px" />
      <template v-for="(item, index) in items">
        <v-list-item :key="item.airportIdent">
          <v-list-item-content>
            <v-list-item-title v-html="item.airportName"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch
              color="#93BD20"
              v-model="item.deliveryEnabled"
              @change="$emit('change', item)"
            ></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider style="margin: 0px" :key="Math.random()" v-if="index != items.length - 1" />
      </template>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import navMenu from "components/nav_menu.vue";
import NavBar from "components/nav_bar";
import clone from "clone";

export default {
    name: "AirportDeliveryManagement",
    components: {
        NavBar,
        navMenu,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {},
    created() {},
};
</script>

<style lang="scss">
@import "~scss/variables";
@import "~scss/mixins";

.desktop-nav {
  @include below(sm) {
    display: none;
  }
}

.delivery-header-text {
  align-content: center;
  font-size: 26px;
  line-height: 26px;
}

.top-wrapper {
  background-color: #fff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: zi(menu) + 20;
}

.mobile-nav {
  display: none;

  @include below(sm) {
    display: block;
  }
}

.dummy-nav {
  @include background_image("", "overflow_logo.svg");
  background-position: 30% center;
}

@import "../scss/variables";

.main-nav {
  font-size: 14px;
  height: 56px;
  align-items: center;
  border-bottom: 1px $brand_color solid;
  background-color: #fff;

  &__logo {
    background-image: url("../img/g_hub_white.svg");
    width: 85px;
    height: 41px;
    background-position: left center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;

    // &:hover {
    //     background-image: url('../img/ghub_logo_active.svg');
    // }
  }

  &__nav_items {
    margin-left: 33px;
  }

  .main-nav__item {
    &-link {
      margin: 0 5px;
      padding: 10px 20px;
      border-radius: 70px;

      &--active,
      &:hover,
      &:focus {
        background-color: $brand_color;
        color: #fff;
      }

      &--warn {
        &:hover,
        &focus {
          background-color: $error_red;
        }
        background-color: $warn_yellow;
        color: #fff;
      }
    }
  }
}
</style>
