var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "shadow--2 dropdown" },
    [
      _vm.items.length
        ? [
            _vm._t("default", function () {
              return [
                _vm._l(_vm.items, function (item) {
                  return [
                    item.run
                      ? [
                          _c(
                            "div",
                            {
                              key: item.display,
                              staticClass: "dropdown__item",
                              on: {
                                click: function ($event) {
                                  _vm.$emit("close")
                                  item.run()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.display) + " ")]
                          ),
                        ]
                      : [
                          _c(
                            "router-link",
                            {
                              key: item.display,
                              staticClass: "dropdown__item",
                              attrs: { to: item.to },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.$emit("close")
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.display))]
                          ),
                        ],
                  ]
                }),
              ]
            }),
          ]
        : _c("div", [_vm._v(" Nothing here. ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }